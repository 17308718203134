import React, { useState, useEffect } from 'react';
import './About.css'; // About.cssファイルをインポート

function About() {
  const [events, setEvents] = useState([]); // イベントデータを保存する状態

  useEffect(() => {
    fetchData(); // ページがロードされたときにデータを取得する関数を呼び出す
  }, []); // 空の依存リストを渡して、最初のレンダリング時にのみ実行されるようにする

  const fetchData = async () => {
    try {
      //const response = await fetch('http://localhost:3001/api/events'); // ExpressサーバーのAPIエンドポイントにリクエストを送信
      const response = await fetch('https://fabulous123.com:3001/api/events'); // ExpressサーバーのAPIエンドポイントにリクエストを送信
      const eventData = await response.json(); // レスポンスデータをJSON形式に変換
      setEvents(eventData); // 取得したイベントデータを状態に保存
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="about-container">
      <h2 className="about-heading">About Us</h2>
      <p className="about-text">fabulousの紹介を書くよ</p>
      
      <h3 className="event-heading">Events</h3>
      <ul>
        {events.map(event => (
          <li key={event.EVENT_ID}>{event.EVENT_NAME}</li>
        ))}
      </ul>
    </div>
  );
}

export default About;
