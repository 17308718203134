import React, { useEffect, useState } from 'react';
import './App.css';

function Home() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`container ${loaded ? 'loaded' : ''}`}>
      <div className="haze"></div>
      <img src={`${process.env.PUBLIC_URL}/logo.jpg`} alt="Team Logo" className="logo" />
    </div>
  );
}

export default Home;
