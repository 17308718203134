import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Menu.css';

function Menu() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleLinkClick = (path) => {
    if (path === location.pathname) {
      // 同じページへのクリックなら強制的にリロード
      window.location.reload();
    } else {
      // 別のページへのクリックならReact Routerのナビゲーション
      navigate(path);
      closeMenu(); // メニューを閉じる
    }
  };

  return (
    <div className="menu-container">
      <div className="menu-button" onClick={toggleMenu}>
        Menu
      </div>
      <div className={`menu-overlay ${menuOpen ? 'open' : ''}`} onClick={e => e.stopPropagation()}>
        <ul className="menu">
          <li onClick={closeMenu} className="menu-link">Close</li>
          <li><Link to="/" className="menu-link" onClick={() => handleLinkClick('/')}>Home</Link></li>
          <li><Link to="/about" className="menu-link" onClick={() => handleLinkClick('/about')}>About</Link></li>
          <li><Link to="/schedule" className="menu-link" onClick={() => handleLinkClick('/schedule')}>Schedule</Link></li>
          <li><Link to="/login" className="menu-link" onClick={() => handleLinkClick('/login')}>Log In</Link></li>
          <li><Link to="/signup" className="menu-link" onClick={() => handleLinkClick('/signup')}>Sign Up</Link></li>
        </ul>
      </div>
    </div>
  );
}

export default Menu;
