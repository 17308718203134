import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import './Schedule.css'; // CSSファイルのインポート

const localizer = momentLocalizer(moment);

const CustomEvent = ({ event }) => (
  <div className="custom-event">
    <div>{event.title}</div>
    <div className="capacity">{event.capacity}</div>
  </div>
);

const MyCalendar = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
    const [isDetailOpen, setIsDetailOpen] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch('https://fabulous123.com:3001/api/events');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Fetched events:', data); // データをログに出力

        // データを整形してCAPACITYを追加
        const formattedEvents = data.map(event => ({
          id: event.EVENT_ID,
          title: event.EVENT_NAME,
          start: new Date(event.START_DATE),
          end: new Date(event.END_DATE),
          capacity: event.CAPACITY // CAPACITYを追加
        }));

        setEvents(formattedEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setIsDetailOpen(true); // イベント詳細を開く
  };

  const handleCloseDetail = () => {
    setSelectedEvent(null);
    setIsDetailOpen(false); // イベント詳細を閉じる
  };

  return (
    <div className="calendar-container">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        defaultView="month"
        views={['month']}
        eventPropGetter={() => ({
          className: 'custom-event-wrapper'
        })}
        components={{
          event: CustomEvent
        }}
        messages={{
          month: '月',
          week: '週',
          day: '日',
          today: '今月',
          previous: '前月',
          next: '来月',
          showMore: total => `さらに表示 (${total})`
        }}
        onSelectEvent={handleEventClick}
      />
      {selectedEvent && (
        <div className="event-detail slide-up">
          <h3>{selectedEvent.title}</h3>
          <p>開始: {moment(selectedEvent.start).format('YYYY-MM-DD HH:mm')}</p>
          <p>終了: {moment(selectedEvent.end).format('YYYY-MM-DD HH:mm')}</p>
          <p>キャパシティ: {selectedEvent.capacity}</p>
          <button className="close-btn" onClick={handleCloseDetail}>Close</button>
        </div>
      )}
    </div>
  );
};

export default MyCalendar;
