import React, { useState } from 'react';
import './Login.css';

function Login() {
  const [userID, setUserID] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://fabulous123.com:3002/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userID, password }),
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);
        // ここでリダイレクトなどの処理を行う
      } else {
        setErrorMessage(data.message); // ログイン失敗時のエラーメッセージを設定
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="login-container">
      <h1>Login</h1>
      <p>Please enter your login details.</p>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="userID">User ID</label>
          <input
            type="text"
            id="userID"
            name="userID"
            value={userID}
            onChange={(e) => setUserID(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="login-button">LOGIN</button>
      </form>
      <div className="forgot-link">
        <a href="/forgot">Forgot your User ID or Password?</a>
      </div>
    </div>
  );
}

export default Login;
