import React from 'react';

function SignUp() {
  return (
    <div>
      <h1>Sign Up</h1>
      <p>Please fill in the form to create an account.</p>
    </div>
  );
}

export default SignUp;
