import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Menu from './Menu';
import Home from './Home';
import About from './contents/About';
import Schedule from './contents/Schedule';
import Login from './contents/Login';
import Signup from './contents/Signup';

function App() {
  const location = useLocation();

  return (
    <div>
      <Menu />
      <Routes key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
      </Routes>
    </div>
  );
}

export default App;
